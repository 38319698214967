import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    }
]

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'account-settings.profile',
        path: `${APP_PREFIX_PATH}/account-settings/profile`,
        component: React.lazy(() => import('views/app-views/account-settings/profile')),
    },
    {
        key: 'manage-product.productList',
        path: `${APP_PREFIX_PATH}/manage-product/product-list`,
        component: React.lazy(() => import('views/app-views/products/product-list')),
    },
    {
        key: 'manage-product.addProduct',
        path: `${APP_PREFIX_PATH}/manage-product/add-product`,
        component: React.lazy(() => import('views/app-views/products/add-product')),
    },
    {
        key: 'manage-product.editProduct',
        path: `${APP_PREFIX_PATH}/manage-product/edit-product`,
        component: React.lazy(() => import('views/app-views/products/edit-product')),
    }
]